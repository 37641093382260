<template>
  <div class="wrapper">
    <img src="../../assets/images/noAuthority/403.png" />
  </div>
</template>

<script>
export default {
  name: 'noAuthority',
};
</script>

<style scoped lang="less">
.wrapper {
  width: 100%;
  height: calc(100vh - 85px);
  background: rgb(240, 242, 245);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: contain;
  }
}
</style>
